import React, { useState } from 'react'
import { Button, Form, Modal } from 'react-bootstrap';
import { baseUrl } from '../env';
import axios from 'axios';

function OtpVarificationModal(props) {
    const { setVisible, visible } = props
    const [isError,setIsError]=useState(false)

    const [OtpData, setOtpData] = useState({
        otp: ''
    });

    const handleInputChange = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        setOtpData({ ...OtpData, [name]: value })
    };
    let token = localStorage.getItem('otptoken')
    const handleSubmit = (e) => {
        // e.preventDefault();
        let data = {
            otp: OtpData.otp,
            token: token
        };

        let config = {
            method: 'POST',
            url: baseUrl + 'customer/otpverification',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            data: data
        };
        setIsError(false)
        axios.request(config)
            .then((response) => {
                handleClose()
                localStorage.setItem('token', response.data.data.token)
                const event = new Event('login');
                event.key = 'login';
                event.value = 'true';
                document.dispatchEvent(event);
            })
            .catch((error) => {
                console.log('Errors:-', error);
                setIsError(true)
            });
    }
    const handleClose = () => {
        setVisible(false)
    };
    return (
        <div>
            <Modal show={visible} >
                <Modal.Header>
                    <Modal.Title>OTP Varification Form</Modal.Title>
                </Modal.Header>
                <Form >
                    <Modal.Body>
                        <Form.Group controlId="formName">
                            <Form.Control type="number"
                                placeholder="Enter Otp Code"
                                value={OtpData.otp}
                                name='otp'
                                onChange={handleInputChange}
                                required
                            />
                        </Form.Group>
                        {isError && <p className='text-danger'>Please enter valid otp?</p>}
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>
                            Close
                        </Button>
                        <Button variant="primary" onClick={handleSubmit}
                            type="button">
                            Varify
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal>
        </div>
    )
}

export default OtpVarificationModal