import React, { useEffect, useState } from 'react'
import { baseUrl } from '../env';
import axios from 'axios';
const status = {
  Pending: 1,
  Accepted: 2,
  Packed: 3,
  Shipped: 4,
  Delivered: 5,
  Rejected:0
}

function OrderList() {

  const [orderStatus, setOrderStatus] = useState([])
  useEffect(() => {
    let token = localStorage.getItem('token')
    axios.get(baseUrl + `order/get`, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        token: token
      }
    })
      .then((res) => {
        res.data.data.forEach(item=>{
          item.totalAmount=0
          item.products.forEach(p=>{
            item.totalAmount+=(p.fprice*p.qty)
          })
        })
        setOrderStatus(res.data.data)
      })
      .catch((err) => {
        console.log('Errors', err);
      })
  }, [])

  return (
    <div>
     
      <div className="container px-1 px-md-4 py-5 mx-auto" >
        {orderStatus?.map((data, i) =>
          <div className="card m-0 mt-1" key={i}>
            <div className="row d-flex justify-content-between px-3 top">
            <div className="d-flex ">
                <div className='progress-status-font'>OrderId: <span className="text-primary font-weight-bold pointer">{data?.orderId}</span></div>
              </div>
              <div className="d-flex ">
                <div className='progress-status-font'>Items: <span className="font-weight-bold">{data?.products?.length}</span></div>
              </div>
              <div className="d-flex">
                <div className='progress-status-font'>Name: <span className="font-weight-bold">{data?.name}</span></div>
              </div>
              <div className="d-flex">
                <div className='progress-status-font'>Mobile No: <span className="font-weight-bold">{data?.mobile}</span></div>
              </div>
              <div className="d-flex">
                <div className='progress-status-font'>Total Amount: <span className="font-weight-bold">₹{data?.totalAmount}</span></div>
              </div>
              
            </div>
            <div className="row d-flex justify-content-center">
              <div className="col-12 col_row">
                <ul id="progressbar" className="text-center">
                  <li className={status[data.status] >= 1 ? 'step0 active' : 'step0'}>
                    <img className="icon" src="https://i.imgur.com/9nnc9Et.png" alt='icon' />
                    <p className="progress-status-font">Order<br />Process</p></li>
                  <li className={status[data.status] >= 2 ? 'step0 active' : 'step0'}>
                    <img className="icon" src="https://i.imgur.com/u1AzR7w.png" alt='icon'/>
                    <p className="progress-status-font">Order<br />Accepted</p></li>
                  <li className={status[data.status] >= 3 ? 'step0 active' : 'step0'}>
                    <img className="icon" src="https://i.imgur.com/TkPm63y.png" alt='icon'/>
                    <p className="progress-status-font">Order<br />Packed</p></li>
                  <li className={status[data.status] >= 4 ? 'step0 active' : 'step0'}>
                    <img className="icon" src="https://i.imgur.com/TkPm63y.png" alt='icon'/>
                    <p className="progress-status-font">Order<br />Shipped</p></li>
                  <li className={status[data.status] >= 5 ? 'step0 active' : 'step0'}>
                    <img className="icon" src="https://i.imgur.com/HdsziHP.png" alt='icon'/>
                    <p className="progress-status-font">Order<br />Delivered</p></li>
                </ul>
              </div>
            </div>
            {!status[data.status] && <p className='text-danger text-center font-weight-bold'>Your order has rejected</p>}
          </div>
        )}
      </div>
    </div>

  )
}

export default OrderList