import React, { useEffect, useState } from "react";
import './Cart.css';
import { Link, useNavigate } from "react-router-dom";
import LoginModal from "./LoginModal";
import { baseUrl } from "../env";
const fontColor={'#fff':'#000','#ffffff':'#000','white':'#000'}
let vendorId = localStorage.getItem('vendorId')
function Cart() {
    const [show, setShow] = useState(false);
    
    const handleShow = () => {
        setShow(true)
    };
    const navigate = useNavigate();

    const [value, setValue] = useState(null)
    const [totalObj, setTotalObj] = useState({
        total:0,
        ftotal:0
    })

    useEffect(()=>{
        calculation()
    },[])   


    const calculation=()=>{
        let str = localStorage.getItem('cart-'+vendorId)
        let cartList = str ? JSON.parse(str) : []
        totalObj.total=0
        totalObj.ftotal=0
        cartList.forEach(item => {
            item.fprice=item.price
            item.offerPrice.forEach(price=>{
                if(price.qty<=item.qty){
                    item.fprice=(item.price-(item.price*price.price/100))
                }
            })
            totalObj.total+=item.qty*item.price
            totalObj.ftotal+=item.qty*item.fprice
            setTotalObj({...totalObj})
       });
       
       setValue([...cartList])
    }
    const addCart = (obj, i) => {
        if (value[i].qty)
            value[i].qty += 1;
        else
            value[i].qty = 1;
        // setValue([...value])
        createUpdateEvent()

    }

    const createUpdateEvent = () => {
        const event = new Event('localstorage');
        event.key = 'test';
        event.value = 'hello';
        localStorage.setItem('cart-'+vendorId, JSON.stringify(value))
        calculation()
        document.dispatchEvent(event);
    }

    const deleteToCart = (obj, i) => {
        if (value[i].qty) {
            if (value[i].qty === 1)
                value.splice(i, 1)
            else
                value[i].qty -= 1
            
            // setValue([...value])            
            createUpdateEvent()
        }
    }

    const AddressPage = () => {
        let otptoken = localStorage.getItem('token')
        if (!otptoken) {
            handleShow()
        }
        else {
            navigate('/getaddress')
        }
    }
    return (
        <>
            
            <section className="shoping-cart spad">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="shoping__cart__table">
                                <table>
                                    <thead>
                                        <tr>
                                            <th className="shoping__product">Products</th>
                                            <th>Price</th>
                                            <th>Offer Price</th>
                                            <th>Quantity</th>
                                            <th>Total</th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {value?.length > 0 && value?.map((item, i) =>
                                            <tr key={'cart'+i}>
                                                <td className="shoping__cart__item">
                                                    <img className='img_size' src={baseUrl + '/file/load/' + item?.images} alt='pickupbill' />
                                                    <h5>{item?.name}<div style={{ height: '25px', width: '25px', textAlign: 'center', margin: '2px',color:fontColor[item?.color]||'#fff', borderRadius: '50%', background: item?.varients[0]?.color }}>
                                                {item?.varients[0]?.size}</div></h5>
                                                    
                                                </td>
                                                <td className="shoping__cart__price">
                                                <del style={{color:'red'}} > ₹{item?.price}</del>
                                                </td>
                                                <td className="shoping__cart__price">
                                                    ₹{item?.fprice}
                                                </td>
                                                <td className="shoping__cart__quantity">
                                                    <div className="quantity">
                                                        <div className="pro-qty">
                                                        <i className="btn btn-sm" onClick={() => deleteToCart(item, i)} >-</i>
                                                            {item?.qty}
                                                        <i className="btn btn-sm" onClick={() => addCart(item, i)}>+</i>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td className="shoping__cart__total">
                                                    ₹{item?.qty * item?.fprice}
                                                </td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="shoping__cart__btns">
                                <Link to='/' className="primary-btn cart-btn">CONTINUE SHOPPING</Link>
                                <Link to='/' className="primary-btn cart-btn cart-btn-right"><span className="icon_loading"></span>
                                    Upadate Cart</Link>
                            </div>
                        </div>
                        <div className="col-lg-6"></div>
                        <div className="col-lg-6">
                            <div className="shoping__checkout">
                                <h5>Cart Total</h5>
                                <ul>
                                    <li>Subtotal <span>₹{totalObj.total} </span></li>
                                    <li>Discount <span>-₹{totalObj.total-totalObj.ftotal} </span></li>
                                    <li>Total <span>₹{totalObj.ftotal}</span></li>
                                </ul>
                                <LoginModal setShow={setShow} show={show} />
                                <i className="primary-btn" style={{cursor:'pointer' }} onClick={AddressPage} >PROCEED TO CHECKOUT</i>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default Cart;