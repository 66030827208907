import React, { useEffect, useState } from "react";
import { getProductById } from "../Api/AllApi";
import { useParams } from "react-router-dom";
import { baseUrl } from "../env";
import Swal from "sweetalert2";
let cartids = [];
let cartlist = [];
let vendorId = localStorage.getItem("vendorId");
const fontColor = { "#fff": "#000", "#ffffff": "#000", white: "#000" };

function ProductDetails() {
  const [productDetails, setProductDetails] = useState({});
  const { id } = useParams();
  const [items, setItems] = useState([]);
  const [selectedImg, setSelectedImg] = useState(false);
  const [selectedVarient, setSelectedVarient] = useState();

  useEffect(() => {
    vendorId = localStorage.getItem("vendorId");
    const str = localStorage.getItem("cart-" + vendorId);
    cartlist = str ? JSON.parse(str) : [];
    loadData();
  }, []);

  const loadData = () => {
    getProductById(id)
      .then((resp) => {
        if (resp.data.data) {
          setSelectedImg(resp.data.data?.images[0]);
          let data = cartlist.filter((item) => item._id == resp.data.data._id);
          if (data.length) {
            resp.data.data.qty = data[0].qty;
          }
          if (resp.data.data && resp.data.data.varients[0]) {
            resp.data.data.price = resp.data.data.varients[0].price;
            resp.data.data.varients[0].isSelected = true;
            setSelectedVarient(resp.data.data.varients[0])
          }
          setProductDetails(resp.data.data);
        }
      })
      .catch((err) => console.log(err));
  };

  const addtoCart = (obj) => {
    let tempObj = {};
    let sv = [];
    if (obj.varients?.length) {
      sv = obj.varients.filter((item) => item.isSelected);
      if (!sv.length)
        return Swal.fire({
          title: "Error !",
          text: "Please select color and size!",
          icon: "error",
          timer: 3000,
          showConfirmButton: false,
        });
    }
    tempObj = { ...obj, varients: sv, qty: 1, images: [selectedImg] };
    cartids = cartlist.map(
      (i) => i._id + i?.varients[0]?.color + i?.varients[0]?.size
    );
    const index = cartids.indexOf(obj._id + sv[0]?.color + sv[0]?.size);
    if (index >= 0) {
      cartlist[index].qty += 1;
    } else {
      cartlist.push(tempObj);
    }
    localStorage.setItem("cart-" + vendorId, JSON.stringify(cartlist));
    setItems([...items]);
    createUpdateEvent();
    Swal.fire({
      title: "Success !",
      text: "Your item successfully added!",
      icon: "success",
      timer: 3000,
      showConfirmButton: false,
    });
  };
  const createUpdateEvent = () => {
    const event = new Event("localstorage");
    event.key = "test";
    event.value = "hello";
    document.dispatchEvent(event);
  };
  const deleteCart = (obj) => {
    if (obj.qty) {
      if (obj.qty === 1) {
        cartlist.splice(cartids.indexOf(obj.id), 1);
        obj.qty = null;
      } else {
        obj.qty -= 1;
        cartlist[cartids.indexOf(obj.id)] = obj;
      }
      localStorage.setItem("cart-" + vendorId, JSON.stringify(cartlist));
      setItems([...items]);
      createUpdateEvent();
    }
  };

  const selectedVarientHandler = (item, i) => {
    console.log("clicked",item)
    item.isSelected = !item.isSelected;
    productDetails.varients.forEach((item, ind) => {
      if (ind !== i) item.isSelected = false;
    });
    setSelectedVarient(item)
    setSelectedImg(item.image);
    productDetails.price = item.price;
    setProductDetails({...productDetails});
  };
  return (
    <div>
      <section className="product-details spad">
        <div className="container">
          <div className="row">
            <div className="col-md-6 col-sm-12">
              <div className="product__details__pic">
                <div className="row" key="row1">
                  <div className="col-3" style={{ padding: "3px",maxHeight:"83vh",overflow:"auto" }}>
                    {productDetails?.images &&
                      productDetails?.images.map((item, i) => (
                        <img
                          key={"mainimg" + i}
                          onClick={() => setSelectedImg(item)}
                          style={{
                            width: "70px",
                            height: "60px",
                            margin: "2px",
                            cursor: "pointer",
                          }}
                          src={baseUrl + "/file/load/" + item}
                          alt=""
                        />
                      ))}
                        <img
                          key={"mainimgv"}
                          onClick={() => setSelectedImg(selectedVarient?.image)}
                          style={{
                            width: "70px",
                            height: "60px",
                            margin: "2px",
                            cursor: "pointer",
                          }}
                          src={baseUrl + "/file/load/" + selectedVarient?.image}
                          alt=""
                        />
                  </div>
                  <div className="col-9" style={{ padding: "5px",maxHeight:"83vh" }}>
                    <div className="product__details__pic__item">
                      <img
                        className="product__details__pic__item--large"
                        src={baseUrl + "/file/load/" + selectedImg}
                        alt=""
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-6 col-sm-12">
              <div className="product__details__text">
                <h3>{productDetails.name}</h3>
                <div className="product__details__price">
                  ₹{productDetails.price}
                </div>
                <h4>Offers</h4>
                <div>
                  <table className="table">
                    <thead>
                      <tr>
                        <th>Qty</th>
                        <th>Discount(%)</th>
                        <th>Price</th>
                      </tr>
                    </thead>
                    <tbody>
                      {productDetails.offerPrice?.map((item, i) => (
                        <tr key={"tbl" + i}>
                          <td>
                            {item.qty} ({item.unit})
                          </td>
                          <td>{item.price}%</td>
                          <td>
                            {productDetails.price -
                              productDetails.price * (item.price / 100)}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
      
                <div className="row p-1 m-1">
              
                  {productDetails?.varients?.length ? (
                    <div className="col-12 p-0">Color & Size</div>
                  ) : (
                    ""
                  )}
                  {productDetails?.varients &&
                    productDetails?.varients.map((item, i) => {
                      return (
                        <>
                          <div
                            onClick={() => selectedVarientHandler(item, i)}
                            style={{
                              height: "27px",
                              paddingTop: `${item.isSelected ? 0 : 2}px`,
                              width: "27px",
                              textAlign: "center",
                              margin: "2px",
                              borderRadius: "50%",
                              color: fontColor[item?.color] || "#fff",
                              cursor: "pointer",
                              background: item?.color,
                              border: `${
                                item.isSelected
                                  ? `2px solid ${
                                      fontColor[item?.color] == "#000"
                                        ? "gray"
                                        : "#000"
                                    }`
                                  : ""
                              }`,
                            }}
                          >
                            {item?.size}
                          </div>
                        </>
                      );
                    })}
                </div>
                {
                  <botton
                    onClick={() => addtoCart(productDetails)}
                    className="primary-btn pointer"
                    style={{ color: "white" }}
                  >
                    ADD TO CARD
                  </botton>
                }
                {/*  <a href="#" className="heart-icon"><span className="icon_heart_alt"></span></a> */}
                <ul>
                  <li>
                    <b>Availability</b> <span>In Stock</span>
                  </li>
                  {/* <li><b>Shipping</b> <span>01 day shipping. <samp>Free pickup today</samp></span></li>
                                    <li><b>Weight</b> <span>0.5 kg</span></li>
                                    <li><b>Share on</b>
                                        <div className="share">
                                            <a href="#"><i className="fa fa-facebook"></i></a>
                                            <a href="#"><i className="fa fa-twitter"></i></a>
                                            <a href="#"><i className="fa fa-instagram"></i></a>
                                            <a href="#"><i className="fa fa-pinterest"></i></a>
                                        </div>
                                    </li> */}
                </ul>
              </div>
            </div>
            <div className="col-lg-12">
              <div className="product__details__tab">
                <ul className="nav nav-tabs" role="tablist">
                  <li className="nav-item">
                    <a
                      className="nav-link active"
                      data-toggle="tab"
                      href="#tabs-1"
                      role="tab"
                      aria-selected="true"
                    >
                      Description
                    </a>
                  </li>
                  {/* <li className="nav-item">
                                        <a className="nav-link" data-toggle="tab" href="#tabs-2" role="tab"
                                            aria-selected="false">Information</a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link" data-toggle="tab" href="#tabs-3" role="tab"
                                            aria-selected="false">Reviews <span>(1)</span></a>
                                    </li> */}
                </ul>
                <div className="tab-content active">
                  <div className="tab-pane active" id="tabs-1" role="tabpanel">
                    <div className="product__details__tab__desc">
                      <h6>Products Infomation</h6>
                      <p>{productDetails.description} </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default ProductDetails;
